function getURLPrams(path) {
  if (!path) {
    return false;
  }

  const param = path.match(/\?([^?]*)$/);

  if (!param || param[1] === '') {
    return false;
  }

  let tmpPrams = param[1].split('&'),
    keyValue = [],
    params = {};

  for (let i = 0, len = tmpPrams.length; i < len; i++) {
    keyValue = tmpPrams[i].split('=');
    params[keyValue[0]] = keyValue[1];
  }

  return params;
}

function getLastPartOfUrl(path) {
  if (!path) {
    return false;
  }
  return path.split('/').at(-1); // URLを'/'で分割して、最後の要素を返す
}

function testServiceOfUrl(url) {
  // YouTubeやVimeo用の正規表現
  const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
  const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/g;

  let service = false;

  // 正規表現でテストする
  if (youtubeRegex.test(url)) {
    service = 'youtube';
  } else if (vimeoRegex.test(url)) {
    service = 'vimeo';
  } else {
    service = false;
  }

  return service;
}

export default function rewriteVideos() {
  const videoRewriteElements = document.querySelectorAll('.video-rewrite');

  videoRewriteElements.forEach(function (element) {
    let url = element.querySelector('.video-rewrite_text').textContent;
    let service = testServiceOfUrl(url);

    if (service == 'youtube') {
      let videoid = getURLPrams(url);
      let iframe = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + videoid.v + '/?modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

      element.querySelector('.iframe-video').innerHTML = iframe;
    } else if (service == 'vimeo') {
      let videoid = getLastPartOfUrl(url);
      let iframe = '<iframe src="https://player.vimeo.com/video/' + videoid + '?h=badge=0&autopause=0&player_id=0&byline=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="vimeo-video"></iframe><script src="https://player.vimeo.com/api/player.js"></script>';

      element.querySelector('.iframe-video').innerHTML = iframe;
    } else {
      return false;
    }
  });

  const iframeRewriteElements = document.querySelectorAll('.iframe-rewrite');
  iframeRewriteElements.forEach(function (element) {
    let iframe = element.querySelector('.iframe-rewrite_text').textContent;
    element.querySelector('.iframe-box').innerHTML = iframe;
  });
}
