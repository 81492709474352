//------------------------------------------------
// レスポシブルテーブル
//------------------------------------------------
export default function responsiveTable() {
  const el = document.querySelectorAll('.responsive-table');
  // エレメントがない場合は処理をしない
  if (!el) return;

  el.forEach(function (el) {
    const table = el.querySelector('table');
    const onResize = function () {
      let width = table.offsetWidth;
      let height = table.offsetHeight;
      let parent = el.parentNode;
      let containerWidth = parent.offsetWidth;
      let ratio = containerWidth / width;
      if (ratio < 1) {
        el.style.height = height * ratio + 'px';
        table.style.transform = 'scale(' + ratio + ')';
      } else {
        el.style.height = '';
        table.style.transform = '';
      }
    };
    table.style.transformOrigin = '0 0';
    window.addEventListener('resize', onResize);
    onResize();
  });
}
